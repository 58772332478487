<template lang="pug">
#video-navigation
  //- icon#menu(data="@icon/menu.svg")
  #help(v-if="true", @click="openConnectToAgent") Help
  #divider(v-if="true")
  #step-info
    #current-step-prompt(v-if="!proceededWithoutVideo") {{ prompt }}
    #current-step(v-if="step && !proceededWithoutVideo") Step {{ step }}
    #step-title(@click="showNavPopup")
      icon#st-menu(data="@icon/menu.svg")
      | {{ title }}

  button#next-button(
    v-if="actionButton.type == 'done'",
    @click="gotoNext()",
    :disabled="!canGoNext"
  ) Next
    icon(data="@icon/arrow-forward.svg")
  
  button#custom-button(
    v-if="actionButton.type == 'custom'",
    @click="gotoNext()",
    :disabled="!canGoNext"
  ) {{ actionButton.text }}

  button#camera-button(
    v-if="actionButton.type == 'camera'",
    @click="gotoNext()",
    :disabled="!canGoNext"
  )
    icon(data="@icon/photo-camera.svg")
    | Take Photo
  TimerButton(
    v-if="actionButton.type == 'timer'",
    :key="stepIndex",
    @complete="gotoNext()",
    :canStartTimer="canGoNext",
    :duration="actionButton.duration",
  )

  VideoStepNavigation(
    :class="{ 'is-visible': showPopup }",
    :data="data",
    :videoProgressMarker="videoProgressMarker",
    :currentVideoIndex="currentVideoIndex",
    @gotoVideo="goToVideo",
    @hideNavPopup="hideNavPopup"
  )
</template>

<script>
import AmplitudeAPI from '@/utils/amplitude'

export default {
  props: {
    title: { type: String, required: true },
    prompt: { type: String, required: true },
    step: { type: String },
    canGoNext: { type: Boolean, required: true },
    actionButton: { type: Object, required: true },
    data: { type: Array, required: true, default: () => [] },
    videoProgressMarker: { type: Number, required: false, default: 0 },
    currentVideoIndex: { type: Number, required: false, default: 0 },
    nudgeActive: { type: Boolean, required: false, default: false },
    // stepIndex used to set a key on the timer button so it won't be reused in the next step
    stepIndex: { type: Number, required: true },
    proceededWithoutVideo: { type: Boolean, required: true },
  },

  data() {
    return {
      videoNav: [],
      showPopup: false,
      stepTimeStamp: Date.now(),
    }
  },

  computed: {},

  watch: {
    nudgeActive: {
      handler: function(newVal, oldVal) {
        if (newVal) {
          // don't call hidePopup because we don't want the video to resume when a nudge is sent
          this.showPopup = false
        }
      },
    },
  },

  methods: {
    gotoNext,
    goToVideo,
    hideNavPopup,
    openConnectToAgent,
    showNavPopup,
  },

  components: {
    TimerButton: require('@/components/TimerButton').default,
    VideoStepNavigation: require('@/components/VideoStepNavigation').default,
  },
}

/* Computed ---------------------------------------------------- */
/* Watch ------------------------------------------------------- */
/* Methods ----------------------------------------------------- */
function goToVideo(videoIndex) {
  this.showPopup = false
  this.$emit('gotoVideo', videoIndex)
}

function gotoNext() {
  this.$emit('complete')
}

function hideNavPopup() {
  this.showPopup = false
  // give the nav popup time to disappear before resuming
  setTimeout(() => {
    this.$emit('resumeVideo')
  }, 500)
}

function openConnectToAgent() {
  this.$emit('pauseVideo')
  this.$modal.open('ConnectToAgent', { allowCancel: true, step: this.title, prompt: this.prompt })
  AmplitudeAPI.logEvent('Help', {
    segment: this.title,
    step: this.prompt,
    kit_type: this.$store.getters['user/getKitType'],
    sku: this.$store.getters['user/getSku'],
    medplum_id: this.$store.getters['user/getMedplumOrderId'],
    barcode: this.$store.getters['user/getBarcodeId'],
    customer: this.$store.getters['user/getConfigKeys']
      ? this.$store.getters['user/getConfigKeys'].carrier
      : null,
    linked_amplitude_id: this.$route.query.q,
    segment_time: Date.now() - this.stepTimeStamp,
    source: this.$route.query.src,
  })
}

function showNavPopup() {
  this.showPopup = true
  this.$emit('pauseVideo')
  AmplitudeAPI.logEvent('Menu', {
    segment: this.title,
    step: this.prompt,
    kit_type: this.$store.getters['user/getKitType'],
    medplum_id: this.$store.getters['user/getMedplumOrderId'],
    barcode: this.$store.getters['user/getBarcodeId'],
    sku: this.$store.getters['user/getSku'],
    linked_amplitude_id: this.$route.query.q,
    customer: this.$store.getters['user/getConfigKeys']
      ? this.$store.getters['user/getConfigKeys'].carrier
      : null,
    segment_time: Date.now() - this.stepTimeStamp,
    source: this.$route.query.src,
  })
}
</script>
